<template>
	<div id="wrapper">
		<label class="switch" :class="value && 'activated'">
			<input type="checkbox" ref="check-input" @input="handleCheck">
			<span class="slide round"></span>
			<span class="slider round" ></span>
		</label>
	</div>
</template>

<script>
	export default {
		name: "ToggleSwitch",
		props: {
			value: {
				type: Boolean,
				required: true
			}
		},

		methods: {
			handleCheck (e) {
				const { target: { checked: _checked } } = e;
				this.$emit("toggle", _checked);
			}
		}
	};
</script>

<style scoped>
	#wrapper {
		position: relative;
	}

	.switch {
		position: relative;
		display: inline-block;
		width: 45px;
		height: 26px;
		margin: 0;
	}

	.switch input {
		opacity: 0;
		width: 0;
		height: 0;
	}

	.activated .slider {
		background-color: #0088DD;
	}

	.activated .slide {
		-webkit-transform: translateX(18px);
		-ms-transform: translateX(18px);
		transform: translateX(18px);
	}

	.slider {
		position: absolute;
		cursor: pointer;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: #ccc;
		-webkit-transition: .4s;
		transition: .4s;
	}

	.slide {
		position: absolute;
		width: 18px;
		height: 18px;
		background: white;
		left: 4px;
		z-index: 18;
		bottom: 4px;
		-webkit-transition: .4s;
		transition: .4s;
		cursor: pointer;
	}

	.round {
		border-radius: 34px;
	}

	.round:before {
		border-radius: 50%;
	}

	#checked-status {
		padding: 0 8px;
	}
</style>
