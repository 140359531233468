<template>
	<main
		:class="fluid ? 'container-fluid' : 'container'"
		class="card tcs-card elevate-2 tcs-top borderless py-3"
		:style="`width: calc(${width || '95'}% - ${
			sideBarWidth + +fluid * 16
		}px) !important; left: ${Math.floor(sideBarWidth / 2)}px;`"
	>
		<slot/>
	</main>
</template>

<script>
	import { mapState } from "vuex";
	export default {
		props: {
			width: {
				type: String
			},
			fluid: {
				type: Boolean,
				default: false
			}
		},
		computed: mapState(["sideBarWidth"])
	};
</script>

<style scoped>
	main {
		margin-top: 80px;
		transition: all ease 0.3s;
		min-height: 70vh;
	}

	@media (min-width: 790px) {
		.card.tcs-card.tcs-top {
			/* border-top: 10px solid #6dcff6 !important; */
			border-top: 10px solid var(--cal-header-template) !important;
		}
	}

	@media (max-width: 790px) {
		.card h1 {
			font-size: 34px;
		}

		.card.tcs-card.tcs-top {
			border: 0;
			min-height: calc(100vh - 70px);
			margin-top: 70px;
		}
	}
</style>
